<template>
    <v-card class="pb-2" :loading="loading">
        <v-card-title class="headline">
            <v-btn small icon @click="close" class="mr-2 ml-n3">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            PROJECT CLOSING PROCESS</v-card-title
        >

        <v-card-text class="pb-1 pt-3">
            <v-list dense>
                <v-list-item
                    v-for="(task, index) in tasks"
                    :key="index"
                    class="pa-0"
                >
                    <v-list-item-content class="pa-0">
                        <v-list-item-title class="d-flex">
                            <p class="mr-4 text-body-1">
                                <v-icon class="mr-2">{{ task.icon }}</v-icon
                                >{{ task.name }}
                            </p>
                            <div>
                                <v-progress-circular
                                    v-if="task.loading"
                                    indeterminate
                                    size="24"
                                    width="2"
                                ></v-progress-circular>
                                <v-icon
                                    v-if="!task.loading && task.checked"
                                    color="green"
                                    >mdi-check-circle</v-icon
                                >
                                <v-icon
                                    v-if="!task.loading && !task.checked"
                                    color="red"
                                    >mdi-close-circle</v-icon
                                >
                            </div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>

        <v-card-actions>
            <v-btn color="green white--text" @click="startTaskChecking"
                ><v-icon size="20" class="mx-0"> mdi-sync </v-icon>RESET</v-btn
            >
            <v-btn
                v-if="pendingTask"
                color="red white--text"
                @click="downloadFile"
                ><v-icon size="20" class="mx-0"> mdi-file-excel </v-icon
                >REPORT</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :disabled="pendingTask"
                @click="sendToClosing"
                >SEND TO CLOSING</v-btn
            >
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
    name: 'ProjectClosingProcess',
    props: {
        project: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            tasks: [
                {
                    name: 'Validating invoicing and delivery',
                    icon: 'mdi-receipt-text-outline',
                    checked: false,
                    loading: true,
                },
                {
                    name: 'Validating reserves and inventory',
                    icon: 'mdi-database-outline',
                    checked: false,
                    loading: true,
                },
                {
                    name: 'Validating purchase orders',
                    icon: 'mdi-cart-outline',
                    checked: false,
                    loading: true,
                },
                {
                    name: 'Validating work orders',
                    icon: 'mdi-factory',
                    checked: false,
                    loading: true,
                },
            ],
            pendingInvoices: {},
            pendingReserves: {},
            pendingPurchases: {},
            pendingWOAndPL: {},
            loading: false,
        }
    },
    computed: {
        pendingTask() {
            for (const task of this.tasks) {
                if (!task.checked && !task.loading) {
                    return true
                }
            }
            return false
        },
    },
    async mounted() {
        try {
            await this.startTaskChecking()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async sendToClosing() {
            try {
                this.loading = true
                await API.sendProjectToTheClosingProcess(this.project.id)
                this.$emit('remove', this.project.id)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async downloadFile() {
            try {
                this.loading = true
                // create workbook
                const wb = XLSX.utils.book_new()
                // set sheets
                if (this.pendingInvoices.pending.length > 0) {
                    let files = []
                    for (const invoice of this.pendingInvoices.pending) {
                        files.push({
                            number: invoice.number,
                            region: invoice.region,
                            status: invoice.status,
                        })
                    }
                    // create sheet
                    const sheet = XLSX.utils.json_to_sheet(files)
                    // append sheet
                    XLSX.utils.book_append_sheet(wb, sheet, 'invoices')
                }
                if (this.pendingReserves.pending.length > 0) {
                    let files = []
                    for (const reserve of this.pendingReserves.pending) {
                        if (reserve.free_use) {
                            files.push({
                                number: '',
                                type: 'material',
                                code: reserve.material,
                                description: reserve.short_material_text,
                            })
                        } else {
                            files.push({
                                number: reserve.number,
                                type: 'reserve',
                                code: reserve.code,
                                description: reserve.description,
                            })
                        }
                    }
                    // create sheet
                    const sheet = XLSX.utils.json_to_sheet(files)
                    // append sheet
                    XLSX.utils.book_append_sheet(
                        wb,
                        sheet,
                        'reserves and materials'
                    )
                }
                if (this.pendingPurchases.pending.length > 0) {
                    let files = []
                    for (const purchase of this.pendingPurchases.pending) {
                        files.push({
                            number: purchase.number,
                            type: purchase.type,
                            status: purchase.status,
                        })
                    }
                    // create sheet
                    const sheet = XLSX.utils.json_to_sheet(files)
                    // append sheet
                    XLSX.utils.book_append_sheet(wb, sheet, 'purchases')
                }
                if (this.pendingWOAndPL.pending.length > 0) {
                    let files = []
                    for (const item of this.pendingWOAndPL.pending) {
                        if (item.type == 'workOrder') {
                            files.push({
                                number: item.number,
                                type: 'work order',
                                code: item.code,
                                status: item.status,
                            })
                        } else if (item.type == 'packingList') {
                            files.push({
                                number: '',
                                type: 'packing list',
                                code: item.code,
                                status: item.status,
                            })
                        }
                    }
                    // create sheet
                    const sheet = XLSX.utils.json_to_sheet(files)
                    // append sheet
                    XLSX.utils.book_append_sheet(
                        wb,
                        sheet,
                        'work orders and packing list'
                    )
                }
                // export Excel file
                XLSX.writeFile(wb, `closing_project_inform.xlsx`)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
        async startTaskChecking() {
            try {
                for (const index in this.tasks) {
                    this.$set(this.tasks, index, {
                        ...this.tasks[index],
                        loading: true,
                    })
                    if (index == 0) {
                        this.pendingInvoices = await API.validateInvoicesByProject(
                            this.project.id
                        )
                        this.$set(this.tasks, index, {
                            ...this.tasks[index],
                            checked: this.pendingInvoices.check,
                            loading: false,
                        })
                    } else if (index == 1) {
                        this.pendingReserves = await API.validateReservesByProject(
                            this.project.id
                        )
                        this.$set(this.tasks, index, {
                            ...this.tasks[index],
                            checked: this.pendingReserves.check,
                            loading: false,
                        })
                    } else if (index == 2) {
                        this.pendingPurchases = await API.validatePurchasesByProject(
                            this.project.id
                        )
                        this.$set(this.tasks, index, {
                            ...this.tasks[index],
                            checked: this.pendingPurchases.check,
                            loading: false,
                        })
                    } else if (index == 3) {
                        this.pendingWOAndPL = await API.validateWorkOrderAndPackingList(
                            this.project.id
                        )
                        this.$set(this.tasks, index, {
                            ...this.tasks[index],
                            checked: this.pendingWOAndPL.check,
                            loading: false,
                        })
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
}
</script>

<style scoped>
.v-list-item {
    margin-bottom: 4px;
}
</style>
